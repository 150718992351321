// src/components/Chat.tsx
import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Grid, LinearProgress, CircularProgress, Box } from "@mui/material";
import OpenAI from "openai";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ReactMarkdown from "react-markdown";

interface MessageDto {
  content: string;
  isUser: boolean;
}

const Message: React.FC<{ message: MessageDto }> = ({ message }) => {
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.open(e.currentTarget.href, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      sx={{
        backgroundColor: message.isUser ? "#e3f2fd" : "#f5f5f5",
        borderRadius: "8px",
        padding: "10px",
        maxWidth: "70%",
      }}
    >
      <ReactMarkdown
        components={{
          a: ({ node, ...props }) => (
            <a {...props} onClick={handleLinkClick} target="_blank" rel="noopener noreferrer" />
          ),
        }}
      >
        {message.content}
      </ReactMarkdown>
    </Box>
  );
};

const validateUrl = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url);
    return response.status === 200;
  } catch (error) {
    console.error(`Invalid URL: ${url}`, error);
    return false;
  }
};

const Chat: React.FC = () => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>([]);
  const [input, setInput] = useState<string>("");
  const [assistant, setAssistant] = useState<any>(null);
  const [thread, setThread] = useState<any>(null);
  const [openai, setOpenai] = useState<any>(null);

  useEffect(() => {
    initChatBot();
  }, []);

  useEffect(() => {
    if (assistant) {
      setMessages([
        {
          content: "Welcome to FOSS. How can I help you?",
          isUser: false,
        },
      ]);
    }
  }, [assistant]);

  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    const assistant = await openai.beta.assistants.retrieve("asst_z7gpqXG3rIXwrwzfj38pkyk4");
    const thread = await openai.beta.threads.create();

    setOpenai(openai);
    setAssistant(assistant);
    setThread(thread);
  };

  const processAnnotations = async (messageContent: any) => {
    const annotations = messageContent.annotations;
    let citations: string[] = [];

    for (let index = 0; index < annotations.length; index++) {
      const annotation = annotations[index];
      const sectionAnchor = annotation.header ? `#${annotation.header.replace(/\s+/g, '_').toLowerCase()}` : '';

      if (annotation.text) {
        messageContent.value = messageContent.value.replace(annotation.text, ` [${index}]`);
      }

      if (annotation.file_citation && annotation.file_citation.file_id) {
        const fullUrl = `https://foss.cyverse.org/${annotation.file_citation.file_id}${sectionAnchor}`;

        if (await validateUrl(fullUrl)) {
          let citationText = `[Explore here](${fullUrl})`;
          citations.push(`[${index}] ${citationText}`);
        } else {
          console.error(`URL validation failed for ${fullUrl}`);
        }
      } else {
        console.warn(`Annotation at index ${index} does not contain a valid file citation`);
      }
    }

    messageContent.value += '\n\n' + citations.join('\n\n');
    return messageContent.value;
  };

  const handleSendMessage = async () => {
    if (!input.trim() || !openai || !thread) return;

    const newMessages = [...messages, { content: input, isUser: true }];
    setMessages(newMessages);
    setInput("");

    await openai.beta.threads.messages.create(thread.id, {
      role: "user",
      content: input,
    });

    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant.id,
    });

    setIsWaiting(true);

    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);

    while (response.status === "in_progress" || response.status === "queued") {
      await new Promise(resolve => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }

    setIsWaiting(false);

    const messageList = await openai.beta.threads.messages.list(thread.id);
    const lastMessage = messageList.data
      .filter((message: any) => message.run_id === run.id && message.role === "assistant")
      .pop();

    if (lastMessage) {
      const processedContent = await processAnnotations(lastMessage.content[0]["text"]);
      setMessages([...newMessages, { content: processedContent, isUser: false }]);
    }
  };

  return (
    <Container sx={{ padding: "0", height: "100vh", display: "flex", flexDirection: "column", backgroundColor: "#f0f0f5" }}>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          padding: 2,
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid container direction="column" spacing={2}>
          {messages.map((message, index) => (
            <Grid item alignSelf={message.isUser ? "flex-end" : "flex-start"} key={index}>
              <Message message={message} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Grid container direction="row" paddingBottom={2} paddingX={2} justifyContent="space-between">
        <Grid item sm={11} xs={9}>
          <TextField
            label="Type your message"
            variant="outlined"
            disabled={isWaiting}
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "4px",
            }}
          />
          {isWaiting && <LinearProgress color="inherit" />}
        </Grid>
        <Grid item sm={1} xs={3}>
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: "#4054b4",
              '&:hover': { backgroundColor: "#324499" },
              height: "56px",
              minWidth: "56px",
              borderRadius: "50%",
              padding: "0",
            }}
            onClick={handleSendMessage}
            disabled={isWaiting}
          >
            {isWaiting ? <CircularProgress color="inherit" size={24} /> : <ArrowForwardIos fontSize="medium" />}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Chat;